import React, {useEffect, useState} from 'react'
import PageTitle from './PageTitle';
import NimfaPage from "../Screens/Nimfa";

const Nimfa = () => {
    const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Нимфа');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Nimfa`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Німфа`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Нимфа`);
   }
}, [lang]);

    return (
        <>
         <PageTitle 
            title={message}
         /> 
          <NimfaPage />  
        </>
    )
}

export default Nimfa
