
import { defineMessages } from 'react-intl';

export default defineMessages({
  message: "Для большей детализации, кликните по фото.",
  price: "Стоимость",
  priceSymbol: " $" ,
  sold: "Kукла продана.",
  notAvailable: "В данный момент не доступна.",

  

  elfe: {
    name: "Эльф",
    descriptionShort: "Выполнена из LivingDoll.",
    description1: "Выполнена из LivingDoll.",
    description2: "Подвижная голова, роспись акрил и пастель. Высота 54 см",    
    priceValue: " 350 "
  },

  

  durotan: {
    name: "Дюротан",
    descriptionShort: "Выполнен в смешанной технике самозастывающая глина, папье-маше.",
    description1: "Выполнен в смешанной технике самозастывающая глина, папье-маше.",
    description2: "Рост 60 см. Статичный, одежда не съемная.",    
    priceValue: " 1200 "    
  },

  blueAngel: {
    name: "Голубой Ангел",
    descriptionShort: "Выполнена из LivingDoll. Статичная.",
    descriptionShort2: "Высота 84 см. ",
    description1: "Выполнена из LivingDoll. Статичная. Высота 84 см.",
    description2: "Использованы натуральные ткани и шёлк, ручная вышивка, японский бисер и кристаллы Сваровски.",    
    priceValue: " 1500 "
  },

  nimfa: {
    name: "Нимфа",
    descriptionShort: "Выполнена из LivingDoll. Статичная.",
    descriptionShort2: "Высота 74 см. ",
    description1: "Выполнена из LivingDoll. Статичная. Высота 74 см. Для куклы использованы натуральные ткани, шёлк, кристалы Сваровски и натуральный жемчуг.",
    description2: "Кукла учавствует в выставке в Испании и в данный момент не доступна к покупке",    
    priceValue: " 1000 "
  },

  spare: {
    priceValue: " 1000 "    
  },

  elis: {
    name: "Элис",
    descriptionShort: "Выполнена из Fimo",
    description1: "Выполнена из Fimo."
    },

    nonne: {
      name: "Nonne",
      descriptionShort: "Выполнена из LivingDoll.",
      description1: "Выполнена из LivingDoll."
      },

  bespoke: {
    name: "Кукла на заказ",
    descriptionShort: "",
    descriptionShort2: "",
    description1: "Эта кукла была выполнена по специальному заказу и передана заказчику по завершении.",
    description2: "Вы можете заказать изготовление куклы по собственному эскизу.",    
    priceValue: ""
  },

  zayka: {
    name: "Зайка",
    descriptionShort: "Выполнена из LivingDoll. Статичная.",
    description1: "Выполнена из LivingDoll. Статичная.",
    description2: "Использованы натуральные ткани и шёлк. Волосы - натуральная коза. Роспись пастель и акриловые краски.", 
    },

    queen: {
    name: "Шахматная Королева",
    descriptionShort: "Выполнена из LivingDoll. Статичная. Высота 59 см.",
    description1: "Выполнена из LivingDoll. Статичная. Высота 59 см.",
    description2: "Использованы натуральные ткани и шёлк, английские кружева, машинная вышивка, ручная вышивка, натуральный жемчуг, японский бисер и кристаллы Сваровски. Волосы - натуральная коза. Роспись пастель и акриловые краски.",
    },

    bal: {
    name: "На Бал!",
    descriptionShort: "Выполнена из LivingDoll. Статичная. Высота 57см.",
    description1: "Выполнена из LivingDoll. Статичная. Высота 57см.",
    description2: "Использованы натуральные ткани и шёлк, английские кружева, машинная вышивка, ручная вышивка. Волосы - натуральная коза. Роспись пастель и акриловые краски."
    },

  header: {
    main: "Главная",
    works: "Мои Работы",
    order: "Как заказать",
    contacts: "Контакты",

    titleInst: "Моя страница в Instagram ",
    titleFB: "Моя страница в Facebook",
    htitleMail: "Напишите сообщение на мой e-mail",
    titleTel: "Позвоните мне",
    
  },

  home: {
    great: "Здравствуйте!",
    welcome: "Меня зовут Валерия. Добро пожаловать в мой кукольный мир!",
    intro1: "Я родилась и живу в Украине в солнечном городе Одессе, на берегу Чёрного моря. Увлечение куклой, пришло ко мне недавно. В 2019 году, я создала свою первую куклу. А дальше меня закружило кукольное ремесло. Я искала себя в этом волшебном мире, пробовала разные техники и материалы, принимала участие в семинарах и мастер-классах. И это только начало, я учусь и продолжаю совершенствоваться в этой непростой науке. Посетила и стала участником нескольких международных кукольных выставок и конкурсов.",
    intro2: "Авторская кукла - это вид особого искусства, где кукла, как полотно художника. Она уникальна, в единственном экземпляре и повторить точную копию, практически, невозможно. Все куклы сделаны вручную, в каждую вкладываю частичку себя, мы проживаем с ней целую жизнь. При создании работ, в большинстве своём, я пользуюсь смешанной техникой, в которой применяю качественные японские и американские самозастывающие и запекаемые полимерные глины, а так же текстиль и папье-маше. Какие-то, из моих кукол, подвижные, какие-то полностью статичные. Есть большие, а есть маленькие. В одежде использую натуральные современные и антикварные материалы: хлопок, шёлк, ткани ручного окрашивания, английские и французские кружева. Украшаю костюмы кукол  ручной вышивкой, ювелирным бисером, жемчугом, стразами Сваровски. Расписаны куклы масляными, акриловыми красками и пастелью.",
    intro3: "Мои авторские куклы, помогут украсить интерьер вашего дома, добавят тепла и уюта, станут отличным подарком на любой случай в жизни. ",
    parting: "С любовью и  уважением,",
    sign: "Валерия."
  },
  

  works: {
    header: "Коллекция моих работ",
    watchBtn: "Посмотреть фото"
  },

  order: {
    header: "Как заказать куклу?",
    great: "Друзья!",
    intro1: "Если у вас и ваших близких праздник, день рождения или юбилей, а вы не знаете что подарить, тогда вам точно стоит обратить внимание на моих кукол.",
    intro2: "Авторская кукла «hand made” хранит тепло живых рук и, создавать её,  так же как и дарить - одно удовольствие!",
    intro3: "Хотите быть оригинальными, получить огромную порцию отличного настроения, незабываемых эмоций и радости, - листайте фотографии и выбирайте. ",
    intro4: "А возможно, вы просто хотите украсить интерьер, или добавить тепла и уюта в ваш дом - подарите себе куклу!",
    toOrder: "Для заказа куклы",
    contactMe: "Вы можете связаться со мной по телефону: ",
    phone: "+380636569708",
    message: "или написать мне на электронную почту:",
    email: "leraod518@gmail.com",
    pay: "Способы оплаты.",
    payMet: "Способы оплаты обговариваются с мастером отдельно. Возможны варианты оплаты наличными, почтовыми переводами, перевод на карту продавца.",
    delivery: "Доставка",
    delivery1: "Доставка осуществляется во все страны мира. ",
    delivery2: "Способы доставки оговариваются с мастером дополнительно.",
    deliveryVar: "Возможны следующие варианты доставки:",
    deliveryPost: "почтой;",
    deliveryComp: "транспортные компании;",
    deliverySelf: "самовывоз;",
    deliveryCur: "курьерская доставка.",
    deliveryCond: "Цены, условия и сроки зависят от выбранных компаний. ",
    deliveryFree: "Доставка по Украине бесплатная."
  },

  contacts: {
    header: "Вы можете связаться со мной",
    byPhone: "По телефону: ",
    messageIn: "Написать мне в:",
    or: "или",
    messageForm: "Отправьте мне сообщение",
    messageFormName: "Ваше имя", 
    messageFormMail: "Ваш email",
    messageFormSubj: "Тема",
    messageFormMsg: "Ваше сообщение",
    messageButton: "Отправить",
    visitPage: "Посетите мою страницу в:",

  }
 
})

 