import React, { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';

import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {  MDBIcon, MDBInput } from 'mdbreact';
import PageTitle from './PageTitle';


export default function Contacts() {

  const [ captcha, setCaptcha ] = useState('');
  const [ triger, setTriger]  = useState(false);
  const onNameChange = () => {
    setTriger(!triger);
  };

useEffect(() => {
 let newCaptcha =
  <div className="g-recaptcha" 
    data-sitekey="6Lft7tIZAAAAAArxqvGdFIr5__mWaEF29pXdYt-8"
    data-callback="recaptchaCallback">
  </div>  ;
   setCaptcha(newCaptcha);
}, [triger]);

const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Конткты');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Contacts`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Контакти`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Конткты`);
   }
}, [lang]);

  return (
    <div>
      <PageTitle 
       title={message}
      />
     
        <Container>
          <Row>
            <Col md='2' lg='3'></Col>
            <Col md='8' lg='6'className='center-text mt-4 blue-text'>
              <h3>
               <FormattedMessage id='contacts.header' defaultMessage={defineMessages.contacts.header} />
              </h3> 
              <hr className='hr-fine-black blue-text'></hr>
                  <FormattedMessage id='contacts.byPhone' defaultMessage={defineMessages.contacts.byPhone} />
                 <br/>
                <a href="tel:+380636569708" target="_blank"><i className="fas fa-phone-alt fa-lg mx-2 my-3 contacts-icons" title="Позвоните мне" />
                  <FormattedMessage id='order.phone' defaultMessage={defineMessages.order.phone} />
                </a> 
                  <p></p>
                   <FormattedMessage id='contacts.messageIn' defaultMessage={defineMessages.contacts.messageIn} />
                  <p></p>
                <a href="https://api.whatsapp.com/send?phone=380636569708" target="_blank">
                  <i className='contacts-icons fab fa-whatsapp fa-lg  my-3' title="Отправьте сообщение в WhatsApp" /><span className='invisible'>-</span>WhatsApp</a>  
               
                <a href="http://t.me/leraod518" target="_blank"><i className="fab fa-telegram fa-lg contacts-icons ml-3 ml-sm-4 my-4" title="Отправьте сообщение в Телеграм"/><span className='invisible'>-</span>Telegram</a>
               
                <a href="viber://add?number=380636569708"><i className="fab fa-viber fa-lg contacts-icons ml-3 ml-sm-4 my-4" title="Отправьте сообщение в Viber"/><span className='invisible'>-</span>Viber</a>
                <p></p>
                 <FormattedMessage id='contacts.or' defaultMessage={defineMessages.contacts.or} />
              </Col>
            <Col md='2' lg='3'></Col>
          </Row>
        <Row>
            <Col md='2' lg='3'></Col>
            <Col  md='8' lg='6'><h4  className='center-text mt-4 blue-text'>
              <FormattedMessage id='contacts.messageForm' defaultMessage={defineMessages.contacts.messageForm} />
             </h4>  
            <hr className='hr-fine-black'></hr>
        <Form action="contact_form.php" method="post">
            <div className="grey-text">
              <MDBInput label={<FormattedMessage id='contacts.messageFormName' defaultMessage={defineMessages.contacts.messageFormName} />} icon="user" name="name" group type="text" validate error="wrong"
                success="right" required onChange={onNameChange}/>
              <MDBInput label={<FormattedMessage id='contacts.messageFormMail' defaultMessage={defineMessages.contacts.messageFormMail} />} icon="envelope" name="email" group type="email" validate error="wrong"
                success="right" required />
              <MDBInput label={<FormattedMessage id='contacts.messageFormSubj' defaultMessage={defineMessages.contacts.messageFormSubj} />} icon="tag" name="subject" group type="text" validate error="wrong" success="right" />
              <MDBInput type="textarea" rows="3" name="message" label={<FormattedMessage id='contacts.messageFormMsg' defaultMessage={defineMessages.contacts.messageFormMsg} />} icon="pencil-alt" />
            </div> 
            
            <Row className="mx-auto justify-content-center my-2">
              <Col className="mx-auto justify-content-center">
                {captcha} 
              </Col> 
            </Row>
          <Button id='send-btn-modal' type='submit'className='wide-button-rounded nav-bar my-4' disabled>
           <FormattedMessage id='contacts.messageButton' defaultMessage={defineMessages.contacts.messageButton} />
           <MDBIcon far icon="paper-plane" className="ml-1" />
          </Button>

        </Form>
        <p></p> 
         <h4  className='center-text mt-4 blue-text'>
          <FormattedMessage id='contacts.visitPage' defaultMessage={defineMessages.contacts.visitPage} />
         </h4>  
         
            <hr className='hr-fine-black'></hr>
            <p className='center-text mt-4'>
              <a href="http://www.instagram.com/" target="_blank"><i className="fab fa-instagram fa-lg ml-2 mr-2 my-3 contacts-icons" title="Моя страница в Instagram"></i>Instagram</a>
              <a href="http://www.facebook.com/valeria.myshanova" target="_blank"><i className="fab fa-facebook fa-lg ml-4 mr-2 my-3 contacts-icons" title="Моя страница в Facebook"></i>Facebook</a>
        

            </p>
              
        

            </Col>
            <Col  md='2' lg='3'></Col>
          </Row>
        </Container>
      
      
    </div>
  )
}
