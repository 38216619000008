import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';

import logo from '../components/assets/logo.jpeg';


export default function Footer() {
  return (
    <div>
      <Container>
      <Row>
          <Col sm={2} md={4}></Col>
          <Col sm={8} md={4}> 
          <Container className='my-5'>
          
             <Image  src={logo} fluid />
           </Container> 
         
          </Col>
          <Col sm={2} md={4}></Col>
        </Row>
      </Container>
      
    </div>
  )
}
