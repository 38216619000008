import React, {useEffect, useState} from 'react';
import BalPage from "../Screens/Bal";
import PageTitle from './PageTitle';


const Bal = () => {

    const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | На Бал!');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | To the Ball!`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | На Бал!`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | На Бал!`);
   }
}, [lang]);
    return (
        <>
         <PageTitle 
          title={message}
         />
            <BalPage />
        </>
    )
}

export default Bal
