import React, {useEffect, useState} from 'react'
import PageTitle from './PageTitle';
import Zakaz2Page from "../Screens/Zakaz2";

const Zakaz2 = () => {
    const lang = localStorage.getItem('lang');  
    const [message, setMessage] = useState('Авторская кукла | Кукла на заказ');
   useEffect(() => {
     if (lang === 'en') {
      setMessage(`Author's Doll | Doll Bespoke`);
     }
     if (lang === 'ua') {
      setMessage(`Авторська лялька | Лялька на замовлення`);
     }
     if (lang === 'ru') {
      setMessage(`Авторская кукла | Кукла на заказ`);
     }
  }, [lang]);
  
      return (
          <>
           <PageTitle 
              title={message}
           /> 
            <Zakaz2Page />  
          </>
      )
  }

export default Zakaz2
