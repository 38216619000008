import React, {useEffect, useState} from 'react';
import PageTitle from './PageTitle';
import ElfePage from "../Screens/Elfe";

const Elfe = () => {

 const lang = localStorage.getItem('lang'); 
  const [message, setMessage] = useState('Авторская кукла | Эльф');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Elfe`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Ельф`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Эльф`);
   }
}, [lang]);

    return (
        <>
         <PageTitle 
            title={message}
         /> 
          <ElfePage />  
        </>
    )
}

export default Elfe
