import React, {useEffect, useState}  from 'react';
import PageTitle from './PageTitle';
import DurotanPage from "../Screens/Durotan";

const Durotan = () => {
    const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Дуротан');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Durotan`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Дуротан`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Дуротан`);
   }
}, [lang]);

    return (
        <>
         <PageTitle 
          title={message}
         /> 
         <DurotanPage />
        </>
    )
}

export default Durotan
