import React, {useEffect, useState}  from 'react';
import PageTitle from './PageTitle';
import ElisPage from "../Screens/Elis";


const Elis = () => {
    const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Элис');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Elise`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Еліс`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Элис`);
   }
}, [lang]);

    return (
        <>
         <PageTitle 
          title={message}
         /> 
         <ElisPage />
        </>
    )
}

export default Elis
