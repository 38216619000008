import React, { Component } from "react";

import "./index.css";
import Header from './components/Header';
import Footer from '../src/pages/Footer';




class App extends Component {
  render() {
    return (
      <>
        <Header />
        <Footer />
              
      </>
    );
  }
}

export default App;
