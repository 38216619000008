import React, { useContext } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container } from 'react-bootstrap';


import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';
import { Context } from '../components/LanguageProvider';



import logo from '../components/assets/logo.jpeg'
import Home from '../pages/Home';
import Works from '../pages/Works';
import Order from '../pages/Order';
import Contacts from '../pages/Contacts';
import Elfe from '../pages/elfe';
import Nimfa from '../pages/nimfa';
import Nonne from '../pages/nonne';
import Zakaz1 from '../pages/zakaz1';
import Zakaz2 from '../pages/zakaz2';
import Durotan from '../pages/durotan';
import Elis from '../pages/elis';
import BlueAngel from '../pages/blueAngel';
import Queen from '../pages/queen';
import Zayka from '../pages/zayka';
import Bal from '../pages/bal';






export default function Header(props) {

  const context = useContext(Context); 

  

 

  
    return (
      <>
      <Router>
      
        <Navbar collapseOnSelect expand='lg' className='nav-bar' variant='dark'>
          
          <Container fluid> 
          <Navbar.Brand href="/">
          <img src={logo} height='100' width='' className='d-inline-block align-top' alt='Logo' rounded></img>
          </Navbar.Brand>           
            <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className='mx-auto pl-4'>               
                <Nav.Link className='nav-bar white-text ml-0 ml-xl-5 pl-xl-5' href='#/'>
                  <FormattedMessage id='header.main' defaultMessage={defineMessages.header.main} />
                </Nav.Link>
                <Nav.Link className='nav-bar white-text' href='#works'>
                  <FormattedMessage id='header.works' defaultMessage={defineMessages.header.works} />
                </Nav.Link>
                <Nav.Link className='nav-bar white-text' href='#order'>
                  <FormattedMessage id='header.order' defaultMessage={defineMessages.header.order} />
                </Nav.Link>
                <Nav.Link className='nav-bar white-text' href='#contacts'>
                  <FormattedMessage id='header.contacts' defaultMessage={defineMessages.header.contacts} />
                </Nav.Link>                


              </Nav>
              <Nav className='d-inline'>            
                <a href="http://www.instagram.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-lg mx-2 my-3 white-text" title="Моя страница в Instagram | My Instagram page"></i></a>
                <a href="http://www.facebook.com/valeria.myshanova" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook fa-lg mx-2 my-3 white-text" title="Моя страница в Facebook | My Facebook account"></i></a>
                <a href="mailto:leraod518@gmail.com" target="_blank" rel="noopener noreferrer"><i className="far fa-envelope fa-lg mx-2 my-3 white-text" title="Напишите сообщение на мой e-mail | Send me an e-mail message"></i></a>            
                <a href="tel:+380636569708" target="_blank" rel="noopener noreferrer"><i className="fas fa-phone-alt fa-lg mx-2 my-3 white-text" title="Позвоните мне | Call me"></i></a> 
                
               
                <select className="justify-content-center nav-bar dropdown-rounded ml-1" onChange={context.selectLang} value={context.locale}>
                 <option disabled></option>
                 <option value="ua">🌐 UA</option>
                 <option value="ru">🌐 RU</option>
                 <option value="en">🌐 EN</option>
                </select> 
                       
              </Nav>               
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/works" component={Works} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/order" component={Order} />
          <Route path="/Elfe" component={Elfe} />
          <Route path="/Nimfa" component={Nimfa} />
          <Route path="/Nonne" component={Nonne} />
          <Route path="/Zakaz1" component={Zakaz1} />
          <Route path="/Zakaz2" component={Zakaz2} />
          <Route path="/Durotan" component={Durotan} />
          <Route path="/Elis" component={Elis} />
          <Route path="/BlueAngel" component={BlueAngel} />
          <Route path="/Queen" component={Queen} />
          <Route path="/Zayka" component={Zayka} />
          <Route path="/Bal" component={Bal} />
        </Switch>
      </Router>
     
      </>
    )
  }




 