import React, {useEffect, useState} from 'react';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';

import { Container, Row, Col, Image } from 'react-bootstrap';

import img6 from '../components/assets/albums/exebitions/img6.jpeg';
import img3 from '../components/assets/albums/exebitions/img3.jpeg';
import home2 from '../components/assets/albums/dolls/home2.jpeg';

import PageTitle from './PageTitle';



export default function Home() {

  const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Кукла на заказ');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Doll Bespoke`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Лялька на замовлення`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Кукла на заказ`);
   }
}, [lang]);

  return (
    <div>
      <PageTitle 
       title={message}
      />        
      <Container>
        <Row  >          
          <Col md={7} className='my-2 my-md-4'>
          <p></p>
          <h4 className='center-text blue-text'>
          <FormattedMessage id='home.great' defaultMessage={defineMessages.home.great} />
          </h4>  
          <p></p>
          <p className='center-text blue-text'>
           <FormattedMessage id='home.welcome' defaultMessage={defineMessages.home.welcome} />
          </p>     
           

           <span className='invisible'>-</span>
           <span className='blue-text'>
            <FormattedMessage id='home.intro1' defaultMessage={defineMessages.home.intro1} />
           </span>
              
          </Col>
          <Col md={5}>
           <Container className='my-2 my-lg-4'>
             <Image  src={img6} fluid rounded className="img-fluid z-depth-3 img-thumbnail"/>
           </Container> 
          </Col>          
        </Row>
        <Row >
        <Col md={5}>
           <Container className='my-2 my-lg-4'>
             <Image  src={home2} fluid rounded className="img-fluid z-depth-3 img-thumbnail"/>
           </Container> 
          </Col> 
         
          <Col md={7} className='my-2 my-lg-4 blue-text'>    

          <span className='invisible'>-</span>
          <FormattedMessage id='home.intro2' defaultMessage={defineMessages.home.intro2} />
          </Col>                  
        </Row>

        <Row  >          
          <Col md={7} className='my-2 my-lg-4 blue-text'>
          <p></p>
          <span className='invisible'>-</span>
          <FormattedMessage id='home.intro3' defaultMessage={defineMessages.home.intro3} />
          <p></p>

          <span className='invisible'>-</span>
          <FormattedMessage id='home.parting' defaultMessage={defineMessages.home.parting} /> 
           <br/>          
           <span className='invisible'>-</span>
           <FormattedMessage id='home.sign' defaultMessage={defineMessages.home.sign} />           
          </Col>
          <Col md={5}>
           <Container className='my-2 my-lg-4'>
             <Image  src={img3} fluid rounded className="img-fluid z-depth-3 img-thumbnail"/>
           </Container> 
          </Col>          
        </Row>
        
      </Container>
      
    </div>
  )
}
