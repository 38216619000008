import React from "react";
import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Container, Row, Col } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "../pages/LightboxPage.css";

import durotan2 from '../components/assets/albums/dolls/durotan2.jpeg';
import durotan3 from '../components/assets/albums/dolls/durotan3.jpeg';
import durotan4 from '../components/assets/albums/dolls/durotan4.jpeg';
import durotan5 from '../components/assets/albums/dolls/durotan5.jpeg';
import durotan1 from '../components/assets/albums/dolls/durotan1.jpeg';
import durotan6 from '../components/assets/albums/dolls/durotan6.jpeg';
import durotan7 from '../components/assets/albums/dolls/durotan7.jpeg';




class durotan extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false,
    images: [    
      durotan2 , durotan1 ,durotan3 ,durotan4 ,durotan5, durotan6, durotan7,
      
      ],
      colWidth: [ 4, 4, 4, 6, 6, 6, 6]
    }
  
    renderImages = () => {
      let photoIndex = -1;
      const { images } = this.state;
  
      return images.map((imageSrc, index) => {
        photoIndex++;
        const privateKey = photoIndex;
        return (
          <MDBCol md={this.state.colWidth[index]} key={photoIndex}>
            <figure>
              <img
                src={imageSrc}
                alt="Gallery"
                className="img-fluid z-depth-1"
                onClick={() =>
                  this.setState({ photoIndex: privateKey, isOpen: true })
                }
              />
            </figure>
          </MDBCol>
        );
      })
    }
  
    render() {
      const { photoIndex, isOpen, images } = this.state;
      return (
        <MDBContainer className="mt-5" >

         <Container>
          <Row>
            <Col md='2'></Col>
            <Col className='center-text' md='8'><h3  className='center-text blue-text'>
              <FormattedMessage id='durotan.name' defaultMessage={defineMessages.durotan.name} /></h3>
            <p className='center-text mt-3 blue-text'>
              <FormattedMessage id='durotan.description1' defaultMessage={defineMessages.durotan.description1} /></p>
              <p className='center-text blue-text'>
              <FormattedMessage id='durotan.description2' defaultMessage={defineMessages.durotan.description2} /></p>
              
              <p className='center-text mt-4 blue-text'>
              <FormattedMessage id='price' defaultMessage={defineMessages.price} />
               <i><FormattedMessage id='durotan.priceValue' defaultMessage={defineMessages.durotan.priceValue} /></i>
               <i><FormattedMessage id='priceSymbol' defaultMessage={defineMessages.priceSymbol} /></i></p>
              
                            <hr className='hr-fine-black'></hr>
              <span className="text-muted">
              <FormattedMessage id='message' defaultMessage={defineMessages.message}/></span>
              
            </Col>
            <Col  md='2'></Col>
          </Row>
         </Container>

          <div className="mdb-lightbox p-3">
            <MDBRow>
              {this.renderImages()}
            </MDBRow>
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              imageTitle={photoIndex + 1 + "/" + images.length}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length
                })
              }
            />
          )}
          
        </MDBContainer >
      );
    }
  }

export default durotan;
