import React, {useEffect, useState} from 'react';
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import { MDBView } from "mdbreact";
import "../index.css";

import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';
import elfe1 from '../components/assets/albums/dolls/elfe10.jpeg';
import nimfa15 from '../components/assets/albums/dolls/nimfa15.jpeg';
import nonne10 from '../components/assets/albums/dolls/nonne10.jpeg';
import zakaz21 from '../components/assets/albums/dolls/zakaz21.jpeg';
import zakaz120 from '../components/assets/albums/dolls/zakaz120.jpeg';
import durotan10 from '../components/assets/albums/dolls/durotan10.jpeg';
import elis10 from '../components/assets/albums/dolls/elis10.jpeg';
import blueAngel10 from '../components/assets/albums/dolls/blueAngel10.jpeg';
import zayka1 from '../components/assets/albums/dolls/zayka10.jpeg';
import queen10 from '../components/assets/albums/dolls/queen10.jpeg';
import bal10 from '../components/assets/albums/dolls/bal10.jpeg';
import { Link } from 'react-router-dom';

import PageTitle from './PageTitle';

export default function Works() {

  const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Мои работы');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | My Works`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Мої Роботи`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Мои работы`);
   }
}, [lang]);




  return (
    <div>
      <PageTitle 
       title={message}
      />
      <Container>
        <Row>
          <Col md='3'/>
          <Col  md='6'><h3  className='center-text my-4 blue-text'>
            <FormattedMessage id='works.header' defaultMessage={defineMessages.header.main} /></h3>
                           <hr className='hr-fine-black'></hr>
          </Col>
          <Col  md='3'/>
        </Row>
      </Container>
      
      <Container className='card-wrapper' >      
         
         <Row className="justify-content-center my-4">

         <Col className='container-flex-column mt-xl-5 my-2' sm='12' md='6' lg='4'>
              <Card className='card-apperance'>
              <MDBView hover zoom>
                <Link to="/Nimfa" className='a-href-card' >                
                  <Card.Img variant="top" src={nimfa15} className='d-block w-100 img-thumbnail' />                  
                </Link>                
              </MDBView>
              
                 <Card.Body>
                 <Card.Title className='center-text blue-text'>
                   <FormattedMessage id='nimfa.name' defaultMessage={defineMessages.nimfa.name} />
                 </Card.Title>
                 <Card.Text className='center-text'>
                   <FormattedMessage id='nimfa.description.short' defaultMessage={defineMessages.nimfa.descriptionShort} />
                   <br />
                   <FormattedMessage id='nimfa.description.short2' defaultMessage={defineMessages.nimfa.descriptionShort2} />

                                                   <p> <i className='invisible'>_</i></p>
                   
                 </Card.Text>                
               </Card.Body>  
               <Card.Footer className='center-text blue-text'>
                  <FormattedMessage id='price' defaultMessage={defineMessages.price} /> 
                 <i> <FormattedMessage id='nimfa.priceValue' defaultMessage={defineMessages.spare.priceValue} /></i>
               
                 <i> <FormattedMessage id='priceSymbol' defaultMessage={defineMessages.priceSymbol} /></i>
                </Card.Footer>
              
               <Card.Footer className='card-footer'>
               <Link to="/Nimfa" className='a-href-card'>
                <Button className='wide-button-rounded nav-bar'>
                 <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                </Button>
                </Link>
                </Card.Footer>              
             </Card> 
             </Col>



             {/* target="_blank" */}

 
             

             <Col className='container-flex-column my-2' sm='12' md='6' lg='4'>
             <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/BlueAngel" className='a-href-card'>
                 <Card.Img variant="top" src={blueAngel10} className='d-block w-100 img-thumbnail' />
                </Link>
               </MDBView>
               <Card.Body>
               <Card.Title className='center-text blue-text'>
                   <FormattedMessage id='blueAngel.name' defaultMessage={defineMessages.blueAngel.name} />
                 </Card.Title>
                 <Card.Text className='center-text'>
                   <FormattedMessage id='blueAngel.description.short' defaultMessage={defineMessages.blueAngel.descriptionShort} />
                   <br />
                   <FormattedMessage id='blueAngel.description.short2' defaultMessage={defineMessages.blueAngel.descriptionShort2} />
                   
                 </Card.Text>                
               </Card.Body>  
               <Card.Footer className='center-text mt-4 blue-text'>
                 <FormattedMessage id='price' defaultMessage={defineMessages.price} /> 
                 <i> <FormattedMessage id='blueAngel.priceValue' defaultMessage={defineMessages.blueAngel.priceValue} /></i>
               
                 <i> <FormattedMessage id='priceSymbol' defaultMessage={defineMessages.priceSymbol} /></i>
               </Card.Footer>
               <Card.Footer className='card-footer '>
               <Link to="/BlueAngel" className='a-href-card'>
                 <Button className='wide-button-rounded nav-bar'>
                  <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                 </Button>
              </Link>
                 </Card.Footer>                          
              </Card> 
             </Col>  

             <Col className='container-flex-column mt-xl-5  my-2' sm='12' md='6' lg='4'>
             <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/Durotan" className='a-href-card'>
                 <Card.Img variant="top" src={durotan10} className='d-block w-100 img-thumbnail' /></Link>
               </MDBView>
               <Card.Body>
                 <Card.Title className='center-text blue-text'>
                   <FormattedMessage id='durotan.name' defaultMessage={defineMessages.durotan.name} />
                 </Card.Title>
                 <Card.Text className='center-text'>
                   <FormattedMessage id='durotan.description.short' defaultMessage={defineMessages.durotan.descriptionShort} />
                   
                 </Card.Text>                
               </Card.Body>  
               <Card.Footer className='center-text mt-4 blue-text'>
                 <FormattedMessage id='price' defaultMessage={defineMessages.price} /> 
                 <i> <FormattedMessage id='durotan.priceValue' defaultMessage={defineMessages.durotan.priceValue} /></i> 
                 <i> <FormattedMessage id='priceSymbol' defaultMessage={defineMessages.priceSymbol} /></i>
               </Card.Footer>
               <Card.Footer className='card-footer '>
               <Link to="/Durotan" className='a-href-card'>
                 <Button className='wide-button-rounded nav-bar'>
                  <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                 </Button></Link>
                 </Card.Footer>                          
              </Card> 
             </Col> 
          </Row> 
         </Container> 
 
         <Container className='card-wrapper '>
         <Row className="justify-content-center my-4">  
          <Col className='container-flex-column my-2' sm='12' md='6' lg='6'>
             <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/Zakaz2" className='a-href-card'>
                 <Card.Img variant="top" src={zakaz21} className='d-block w-100 img-thumbnail' /></Link>
               </MDBView>
               <Card.Body>
               <Card.Title className='center-text blue-text'>
                 <FormattedMessage id='bespoke.name' defaultMessage={defineMessages.bespoke.name} />
               </Card.Title>
               
               </Card.Body> 
               <Card.Footer className='center-text blue-text'>
                 <FormattedMessage id='sold' defaultMessage={defineMessages.sold} />
                </Card.Footer>
               
               <Card.Footer className='card-footer'>
               <Link to="/Zakaz2" className='a-href-card'>
                 <Button className='wide-button-rounded nav-bar' >
                  <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                 </Button></Link>
                </Card.Footer>                          
              </Card> 
             </Col>  
 
             <Col className='container-flex-column my-2' sm='12' md='6' lg='6'>        
              <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/Zakaz1" className='a-href-card'>
                 <Card.Img variant="top" src={zakaz120} className='d-block w-100 img-thumbnail' /></Link>
               </MDBView>
               <Card.Body>
               <Card.Title className='center-text blue-text'>
                 <FormattedMessage id='bespoke.name' defaultMessage={defineMessages.bespoke.name} />
               </Card.Title>
               
               </Card.Body> 
               <Card.Footer className='center-text blue-text'>
                 <FormattedMessage id='sold' defaultMessage={defineMessages.sold} />
                </Card.Footer>
               <Card.Footer className='card-footer'>
               <Link to="/Zakaz1" className='a-href-card'>
                 <Button className='wide-button-rounded nav-bar'>
                  <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                 </Button></Link>
                 </Card.Footer>                           
              </Card>
             </Col>
           </Row> 
       </Container>


       <Container className='card-wrapper '>
         <Row className="justify-content-center my-4"> 

         <Col className='container-flex-column my-2' sm='12' md='6' lg='4'>
              <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/Elfe" className='a-href-card'>
                 <Card.Img variant="top" src={elfe1} className='d-block w-100 img-thumbnail' /></Link>
               </MDBView>              
               <Card.Body>
                 <Card.Title className='center-text blue-text'>

                                   

                   <FormattedMessage id='elfe.name' defaultMessage={defineMessages.elfe.name} />
                 </Card.Title>
                 <Card.Text className='center-text'>
                   <p/>
                   <FormattedMessage id='elfe.description.short' defaultMessage={defineMessages.elfe.descriptionShort} />
                   <br />
                   <FormattedMessage id='elfe.description2' defaultMessage={defineMessages.elfe.description2} />
                  <br />
                   
                 </Card.Text>                
               </Card.Body>  
               <Card.Footer className='center-text mt-4 blue-text'>
                 
                 <FormattedMessage id='sold' defaultMessage={defineMessages.sold} />
               </Card.Footer>
               <Card.Footer className='card-footer'><Link to="/Elfe" className='a-href-card'>
                <Button className='wide-button-rounded nav-bar' >
                 <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                </Button></Link>
               </Card.Footer>              
              </Card>              
             </Col>


 
             <Col className='container-flex-column my-2' sm='12' md='6' lg='4'>        
              <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/Elis" className='a-href-card'>
                 <Card.Img variant="top" src={elis10} className='d-block w-100 img-thumbnail' /></Link>
               </MDBView>
               <Card.Body>
                 <Card.Title className='center-text blue-text'>
                   <FormattedMessage id='elis.name' defaultMessage={defineMessages.elis.name} />
                 </Card.Title>
                 <Card.Text className='center-text'>
                   <p/>
                   <FormattedMessage id='elis.description.short' defaultMessage={defineMessages.elis.descriptionShort} />
                   <br />
                   
                 </Card.Text>                
               </Card.Body>  
               <Card.Footer className='center-text blue-text'>
                 <FormattedMessage id='sold' defaultMessage={defineMessages.sold} />
                </Card.Footer>
               <Card.Footer className='card-footer'>
               <Link to="/Elis" className='a-href-card'>
                 <Button className='wide-button-rounded nav-bar' >
                  <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                 </Button></Link>
                </Card.Footer>                           
              </Card>
             </Col>





             <Col className='container-flex-column my-2' sm='12' md='6' lg='4'>
             <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/Nonne" className='a-href-card'>
                 <Card.Img variant="top" src={nonne10} className='d-block w-100 img-thumbnail' /></Link>
               </MDBView>
               <Card.Body>
                 <Card.Title className='center-text blue-text'>
                   <FormattedMessage id='nonne.name' defaultMessage={defineMessages.nonne.name} />
                 </Card.Title>
                 <Card.Text className='center-text'>
                   <p/>
                   <FormattedMessage id='nonne.description.short' defaultMessage={defineMessages.nonne.descriptionShort} />
                   <br />
                   
                   <p> <i className='invisible'>_</i></p>
                   <p> <i className='invisible'>_</i></p>
                   
                 </Card.Text>                
               </Card.Body>  
               <Card.Footer className='center-text blue-text'>
                 <FormattedMessage id='sold' defaultMessage={defineMessages.sold} />
                </Card.Footer>
               <Card.Footer className='card-footer'>
                 <Link to="/Nonne" className='a-href-card'>
                 <Button className='wide-button-rounded nav-bar'>
                  <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                 </Button></Link>
               </Card.Footer>              
             </Card>                
           </Col>
             <Col md='1'></Col>

           </Row> 
       </Container>


        <Container className='card-wrapper '>
         <Row className="justify-content-center my-4"> 

         <Col className='container-flex-column my-2' sm='12' md='6' lg='4'>
              <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/Zayka" className='a-href-card'>
                 <Card.Img variant="top" src={zayka1} className='d-block w-100 img-thumbnail' /></Link>
               </MDBView>              
               <Card.Body>
                 <Card.Title className='center-text blue-text'>

                                   

                   <FormattedMessage id='zayka.name' defaultMessage={defineMessages.zayka.name} />
                 </Card.Title>
                 <Card.Text className='center-text'>
                   <p/>
                   <FormattedMessage id='zayka.description.short' defaultMessage={defineMessages.zayka.descriptionShort} />
                   
                   
                  
                   
                 </Card.Text>                
               </Card.Body>  
               <Card.Footer className='center-text mt-4 blue-text'>
                 
                 <FormattedMessage id='sold' defaultMessage={defineMessages.sold} />
               </Card.Footer>
               <Card.Footer className='card-footer'>
                 <Link to="/Zayka" className='a-href-card'>
                  <Button className='wide-button-rounded nav-bar' >
                   <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                  </Button>
                </Link>
               </Card.Footer>              
              </Card>              
             </Col>


 
             <Col className='container-flex-column mt-xl-5  my-2' sm='12' md='6' lg='4'>        
              <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/Queen" className='a-href-card'>
                 <Card.Img variant="top" src={queen10} className='d-block w-100 img-thumbnail' /></Link>
               </MDBView>
               <Card.Body>
                 <Card.Title className='center-text blue-text'>
                   <FormattedMessage id='queen.name' defaultMessage={defineMessages.queen.name} />
                 </Card.Title>
                 <Card.Text className='center-text'>
                   <p/>
                   <FormattedMessage id='queen.description.short' defaultMessage={defineMessages.queen.descriptionShort} />
                   <br />
                   
                 </Card.Text>                
               </Card.Body>  
               <Card.Footer className='center-text blue-text'>
                 <FormattedMessage id='sold' defaultMessage={defineMessages.sold} />
                </Card.Footer>
               <Card.Footer className='card-footer'>
               <Link to="/Queen" className='a-href-card'>
                 <Button className='wide-button-rounded nav-bar' >
                  <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                 </Button></Link>
                </Card.Footer>                           
              </Card>
             </Col>





             <Col className='container-flex-column my-2' sm='12' md='6' lg='4'>
             <Card className='card-apperance'>
               <MDBView hover zoom>
               <Link to="/Bal" className='a-href-card'>
                 <Card.Img variant="top" src={bal10} className='d-block w-100 img-thumbnail' />
                </Link>
               </MDBView>
               <Card.Body>
                 <Card.Title className='center-text blue-text'>
                   <FormattedMessage id='bal.name' defaultMessage={defineMessages.bal.name} />
                 </Card.Title>
                 <Card.Text className='center-text'>
                   <p/>
                   <FormattedMessage id='bal.description.short' defaultMessage={defineMessages.bal.descriptionShort} />
                  
                   
              
                   
                 </Card.Text>                
               </Card.Body>  
               <Card.Footer className='center-text blue-text'>
                 <FormattedMessage id='sold' defaultMessage={defineMessages.sold} />
                </Card.Footer>
               <Card.Footer className='card-footer'>
                 <Link to="/Bal" className='a-href-card'>
                 <Button className='wide-button-rounded nav-bar'>
                  <FormattedMessage id='works.watchBtn' defaultMessage={defineMessages.works.watchBtn} />
                 </Button>
              </Link>
               </Card.Footer>              
             </Card>                
           </Col>
             <Col md='1'></Col>

           </Row> 
       </Container>
      
    </div>
  )
}
