import React from "react";

import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Container, Row, Col } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "../pages/LightboxPage.css";

import zakaz22 from '../components/assets/albums/dolls/zakaz22.jpeg';
import zakaz23 from '../components/assets/albums/dolls/zakaz23.jpeg';
import zakaz24 from '../components/assets/albums/dolls/zakaz24.jpeg';
import zakaz25 from '../components/assets/albums/dolls/zakaz25.jpeg';
import zakaz21 from '../components/assets/albums/dolls/zakaz21.jpeg';



class zakaz2 extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false,
    images: [    
       zakaz23 ,zakaz22 , zakaz25, zakaz21 ,zakaz24
      
      ],
      colWidth: [  4, 4, 4, 6, 6,]
    }
  
    renderImages = () => {
      let photoIndex = -1;
      const { images } = this.state;
  
      return images.map((imageSrc, index) => {
        photoIndex++;
        const privateKey = photoIndex;
        return (
          <MDBCol md={this.state.colWidth[index]} key={photoIndex}>
            <figure>
              <img
                src={imageSrc}
                alt="Gallery"
                className="img-fluid z-depth-1"
                onClick={() =>
                  this.setState({ photoIndex: privateKey, isOpen: true })
                }
              />
            </figure>
          </MDBCol>
        );
      })
    }
  
    render() {
      const { photoIndex, isOpen, images } = this.state;
      return (
        <MDBContainer className="mt-5 p-3" >

<Container>
          <Row>
          <Col md='2'></Col>
            <Col className='center-text' md='8'><h3  className='center-text blue-text'>
            <FormattedMessage id='bespoke.name' defaultMessage={defineMessages.bespoke.name} /></h3>
            <p className='center-text mt-3 blue-text'>
              <FormattedMessage id='bespoke.description1' defaultMessage={defineMessages.bespoke.description1} />
            </p>
            <p className='center-text mt-3 blue-text'>
              <FormattedMessage id='bespoke.description2' defaultMessage={defineMessages.bespoke.description2} />
            </p>
              
                            
              <span className='blue-text'>
               <i><FormattedMessage id='sold' defaultMessage={defineMessages.sold} /></i>
              </span>
             
              
                            <hr className='hr-fine-black'></hr>
               <span className="text-muted">
                <FormattedMessage id='message' defaultMessage={defineMessages.message}/>
               </span>
              
            </Col>
            <Col  md='2'></Col>
          </Row>
         </Container>

          <div className="mdb-lightbox p-3">
            <MDBRow>
              {this.renderImages()}
            </MDBRow>
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              imageTitle={photoIndex + 1 + "/" + images.length}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length
                })
              }
            />
          )}
          
        </MDBContainer >
      );
    }
  }

export default zakaz2;
