import React from "react";

import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Container, Row, Col } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "../pages/LightboxPage.css";

import blueAngel2 from '../components/assets/albums/dolls/blueAngel2.jpeg';
import blueAngel3 from '../components/assets/albums/dolls/blueAngel3.jpeg';
import blueAngel4 from '../components/assets/albums/dolls/blueAngel4.jpeg';
import blueAngel5 from '../components/assets/albums/dolls/blueAngel5.jpeg';
import blueAngel1 from '../components/assets/albums/dolls/blueAngel1.jpeg';
import blueAngel6 from '../components/assets/albums/dolls/blueAngel6.jpeg';
import blueAngel7 from '../components/assets/albums/dolls/blueAngel7.jpeg';
import blueAngel8 from '../components/assets/albums/dolls/blueAngel8.jpeg';
import blueAngel9 from '../components/assets/albums/dolls/blueAngel9.jpeg';
import blueAngel11 from '../components/assets/albums/dolls/blueAngel11.jpeg';





class BlueAngel extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false,
    images: [    
      blueAngel1 , blueAngel2 ,blueAngel3 ,blueAngel4 ,blueAngel5 ,
      blueAngel6 , blueAngel7 , blueAngel8 , blueAngel9 , blueAngel11 ,
      
      ],
      colWidth: [ 4, 4, 4, 4, 4, 4, 6, 6, 6, 6 ]
    }
  
    renderImages = () => {
      let photoIndex = -1;
      const { images } = this.state;
  
      return images.map((imageSrc, index) => {
        photoIndex++;
        const privateKey = photoIndex;
        return (
          <MDBCol md={this.state.colWidth[index]} key={photoIndex}>
            <figure>
              <img
                src={imageSrc}
                alt="Gallery"
                className="img-fluid z-depth-1"
                onClick={() =>
                  this.setState({ photoIndex: privateKey, isOpen: true })
                }
              />
            </figure>
          </MDBCol>
        );
      })
    }
  
    render() {
      const { photoIndex, isOpen, images } = this.state;
      return (
        <MDBContainer className="mt-5" >
         <Container>
          <Row>
            <Col md='2'></Col>
            <Col className='center-text' md='8'><h3  className='center-text blue-text'>
            <FormattedMessage id='blueAngel.name' defaultMessage={defineMessages.blueAngel.name} /></h3>
            <p className='center-text mt-3 blue-text'>
              <FormattedMessage id='blueAngel.description1' defaultMessage={defineMessages.blueAngel.description1} /></p>
              <p className='center-text blue-text'>
              <FormattedMessage id='blueAngel.description2' defaultMessage={defineMessages.blueAngel.description2} /></p>
              
              <p className='center-text mt-4 blue-text'>
              <FormattedMessage id='price' defaultMessage={defineMessages.price} />
               <i><FormattedMessage id='blueAngel.priceValue' defaultMessage={defineMessages.blueAngel.priceValue} /></i>
               <i><FormattedMessage id='priceSymbol' defaultMessage={defineMessages.priceSymbol} /></i></p>
              
                            <hr className='hr-fine-black'></hr>
              <span className="text-muted">
              <FormattedMessage id='message' defaultMessage={defineMessages.message}/></span>
              
            </Col>
            <Col  md='2'></Col>
          </Row>
         </Container>
          <div className="mdb-lightbox p-3">
            <MDBRow>
              {this.renderImages()}
            </MDBRow>
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              imageTitle={photoIndex + 1 + "/" + images.length}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length
                })
              }
            />
          )}
          
        </MDBContainer >
      );
    }
  }

export default BlueAngel;
