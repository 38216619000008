import React, {useEffect, useState}  from 'react';
import PageTitle from './PageTitle';
import QueenPage from "../Screens/Queen"

const Queen = () => {

    const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Шахматная королева');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Chess queen`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Шахова королева`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Шахматная королева`);
   }
}, [lang]);

    return (
        <>
         <PageTitle 
          title={message}
         /> 
         <QueenPage />
        </>
    )
}

export default Queen
