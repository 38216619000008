import React from "react";

import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Container, Row, Col } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "../pages/LightboxPage.css";

import elis2 from '../components/assets/albums/dolls/elis2.jpeg';


import elis1 from '../components/assets/albums/dolls/elis1.jpeg';



class Elis extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false,
    images: [    
      elis1 ,elis2 
      
      ],
      colWidth: [ 6, 6]
    }
  
    renderImages = () => {
      let photoIndex = -1;
      const { images } = this.state;
  
      return images.map((imageSrc, index) => {
        photoIndex++;
        const privateKey = photoIndex;
        return (
          <MDBCol md={this.state.colWidth[index]} key={photoIndex}>
            <figure>
              <img
                src={imageSrc}
                alt="Gallery"
                className="img-fluid z-depth-1"
                onClick={() =>
                  this.setState({ photoIndex: privateKey, isOpen: true })
                }
              />
            </figure>
          </MDBCol>
        );
      })
    }
  
    render() {
      const { photoIndex, isOpen, images } = this.state;
      return (
        <MDBContainer className="mt-5 p-3" >

        <Container>
          <Row>
            <Col md='2'></Col>
            <Col className='center-text' md='8'><h3  className='center-text blue-text'>
            <FormattedMessage id='elis.name' defaultMessage={defineMessages.elis.name} /></h3>
            <p className='center-text mt-3 blue-text'>
              <FormattedMessage id='elis.description1' defaultMessage={defineMessages.elis.description1} /></p>
            
                       
              <span className='blue-text'>
               <i><FormattedMessage id='sold' defaultMessage={defineMessages.sold} /></i>
              </span>
              
              
                            <hr className='hr-fine-black'></hr>
               <span className="text-muted">
                <FormattedMessage id='message' defaultMessage={defineMessages.message}/>
               </span>
              
            </Col>
            <Col  md='2'></Col>
          </Row>
         </Container>

          <div className="mdb-lightbox p-3">
            <MDBRow>
              {this.renderImages()}
            </MDBRow>
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              imageTitle={photoIndex + 1 + "/" + images.length}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length
                })
              }
            />
          )}
          
        </MDBContainer >
      );
    }
  }

export default Elis;
