import React, {useEffect, useState}  from 'react';
import PageTitle from './PageTitle';
import BlueAngelPage from "../Screens/BlueAngel";

const BlueAngel = () => {
    const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Голубой Ангел');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Blue Angel`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Блакитний Янгол`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Голубой Ангел`);
   }
}, [lang]);

    return (
        <>
         <PageTitle 
          title={message}
         /> 
         <BlueAngelPage />
        </>
    )
}

export default BlueAngel
