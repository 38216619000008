import React from "react";

import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Container, Row, Col } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "../pages/LightboxPage.css";

import zayka1 from '../components/assets/albums/dolls/zayka1.jpeg';
import zayka2 from '../components/assets/albums/dolls/zayka2.jpeg';
import zayka3 from '../components/assets/albums/dolls/zayka3.jpeg';
import zayka4 from '../components/assets/albums/dolls/zayka4.jpeg';
import zayka5 from '../components/assets/albums/dolls/zayka5.jpeg';
import zayka6 from '../components/assets/albums/dolls/zayka6.jpeg';
import zayka7 from '../components/assets/albums/dolls/zayka7.jpeg';
import zayka8 from '../components/assets/albums/dolls/zayka8.jpeg';
import zayka9 from '../components/assets/albums/dolls/zayka9.jpeg';

class Zayka extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false,
    images: [    
      zayka1, zayka2, zayka3, zayka4, zayka5, zayka9, zayka7, zayka8, zayka6,      
      ],
      colWidth: [ 4, 4, 4, 4, 4, 4, 12, 6, 6 ]
    }
  
    renderImages = () => {
      let photoIndex = -1;
      const { images } = this.state;
  
      return images.map((imageSrc, index) => {
        photoIndex++;
        const privateKey = photoIndex;
        return (
          <MDBCol md={this.state.colWidth[index]} key={photoIndex}>
            <figure>
              <img
                src={imageSrc}
                alt="Gallery"
                className="img-fluid z-depth-1"
                onClick={() =>
                  this.setState({ photoIndex: privateKey, isOpen: true })
                }
              />
            </figure>
          </MDBCol>
        );
      })
    }
  
    render() {
      const { photoIndex, isOpen, images } = this.state;
      return (
        <MDBContainer className="mt-5 p-3" >

         <Container>
          <Row>
            <Col md='2'></Col>
            <Col className='center-text' md='8'><h3  className='center-text blue-text'>
            <FormattedMessage id='zayka.name' defaultMessage={defineMessages.zayka.name} /></h3>
            <p className='center-text mt-3 blue-text'>
              <FormattedMessage id='zayka.description1' defaultMessage={defineMessages.zayka.description1} /></p>
              <p className='center-text blue-text'>
              <FormattedMessage id='zayka.description2' defaultMessage={defineMessages.zayka.description2} /></p>
              
              <p className='center-text mt-4 blue-text'>
                <span className='blue-text'>
                 <i><FormattedMessage id='sold' defaultMessage={defineMessages.sold} /></i>
                </span>
              </p>
              
                            <hr className='hr-fine-black'></hr>
              <span className="text-muted">
              <FormattedMessage id='message' defaultMessage={defineMessages.message}/></span>
              
            </Col>
            <Col  md='2'></Col>
          </Row>
         </Container>

          <div className="mdb-lightbox p-3">
            <MDBRow>
              {this.renderImages()}
            </MDBRow>
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              imageTitle={photoIndex + 1 + "/" + images.length}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length
                })
              }
            />
          )}
          
        </MDBContainer >
      );
    }
  }

export default Zayka;
