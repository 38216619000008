import React, {useEffect, useState} from 'react'
import PageTitle from './PageTitle';
import NonnePage from "../Screens/Nonne";


const Nonne = () => {

const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Nonne');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Nonne`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Nonne`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Nonne`);
   }
}, [lang]);

    return (
        <>
         <PageTitle 
            title={message}
         /> 
          <NonnePage />  
        </>
    )
}

export default Nonne
