import React, {useEffect, useState} from 'react';

import { FormattedMessage } from 'react-intl';
import defineMessages from '../components/messages';

import { Container, Row, Col, Image } from "react-bootstrap";
import { MDBView } from "mdbreact";
import nimfa from '../components/assets/albums/dolls/nimfa2.jpeg';
import durotan3 from '../components/assets/albums/dolls/durotan3.jpeg';
import blueAngel5 from '../components/assets/albums/dolls/blueAngel5.jpeg';
import PageTitle from './PageTitle';

export default function Order() {

  const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Заказать Куклу');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Purchase a Doll`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Замовити Ляльку`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Заказать Куклу`);
   }
}, [lang]);

  return (
    <div>

      <PageTitle 
       title={message}
      />
      <Container>
          <Row>
            <Col md='2'></Col>
            <Col  md='8'><h3  className='center-text mt-4 blue-text'>
            <FormattedMessage id='order.header' defaultMessage={defineMessages.order.header} />
            </h3>
            <hr className='hr-fine-black'></hr>
             <p className='center-text blue-text'>
               <FormattedMessage id='order.great' defaultMessage={defineMessages.order.great} />
             </p>
             <span className='blue-text'>
              <FormattedMessage id='order.intro1' defaultMessage={defineMessages.order.intro1} /> 
             </span>
                             
                <p className='blue-text'>
                 <FormattedMessage id='order.intro2' defaultMessage={defineMessages.order.intro2} />
                </p>
                <p className='blue-text'>
                 <FormattedMessage id='order.intro3' defaultMessage={defineMessages.order.intro3} />
                </p>
                <p className='blue-text'> 
                 <FormattedMessage id='order.intro4' defaultMessage={defineMessages.order.intro4} />
                </p>
                </Col>
                <Col  md='2'></Col>
                </Row>

                <Row className="justify-content-center" >
                  <Col sm='12' md='1'/>
                  <Col sm='12' md='10'>
                    <Row className="justify-content-center">
                      <Col sm='12' md='4' className="justify-content-center">                       
                       <MDBView hover zoom >
                         <div className="d-flex justify-content-center">
                           <a href="#Nimfa" target="_blank" className='a-href-card'>
                            <Image  src={nimfa} rounded className="max-height-200 img-fluid z-depth-3 img-thumbnail"/>
                          </a>
                         </div>
                           <br/>
                            <div className="d-flex justify-content-center">
                               <h5 className='blue-text'>
                                <FormattedMessage id='nimfa.name' defaultMessage={defineMessages.nimfa.name} />
                              </h5>
                            </div>
                       </MDBView>  
                      </Col>
                      <Col sm='12' md='4' className="justify-content-center">
                       
                        <MDBView hover zoom>
                        <div className="d-flex justify-content-center">
                           <a href="#BlueAngel" target="_blank" className='a-href-card'>
                            <Image  src={blueAngel5} rounded className="max-height-200 img-fluid z-depth-3 img-thumbnail"/>
                          </a> 
                        </div>
                           <br/> 
                           <div className="d-flex justify-content-center"> 
                            <h5 className='blue-text'>
                              <FormattedMessage id='blueAngel.name' defaultMessage={defineMessages.blueAngel.name} />                        
                            </h5>
                          </div>
                        </MDBView> 
                      </Col>
                      <Col sm='12' md='4' className="justify-content-center">
                     
                      <MDBView hover zoom> 
                      <div className="d-flex justify-content-center">
                         <a href="#Durotan" target="_blank" className='a-href-card'>                         
                          <Image  src={durotan3} rounded className="max-height-200 img-fluid z-depth-3 img-thumbnail"/>
                         </a>
                      </div>
                          <br/>
                          <div className="d-flex justify-content-center">
                             <h5 className='blue-text'>
                               <FormattedMessage id='durotan.name' defaultMessage={defineMessages.durotan.name} />
                             </h5>
                          </div>
                         
                         </MDBView>  
                      </Col>
                    </Row> 
                  </Col>
                  <Col sm='12' md='1'/>
                </Row>

                <Row>
            <Col md='2'></Col>
            <Col  md='8'>
              
                <p className=' center-text mt-4'><h5 className='blue-text'><i>
                 <FormattedMessage id='order.toOrder' defaultMessage={defineMessages.order.toOrder} />
                </i></h5></p>
                <hr className='hr-fine-black width-120'></hr>
                <p className='blue-text'> 
                 <FormattedMessage id='order.contactMe' defaultMessage={defineMessages.order.contactMe} /><a href="tel:+380636569708" target="_blank">
                 <FormattedMessage id='order.phone' defaultMessage={defineMessages.order.phone} />
                 </a> <br />
                 <FormattedMessage id='order.message' defaultMessage={defineMessages.order.message} /> 
                <a href="mailto:leraod518@gmail.com" target="_blank">
                <FormattedMessage id='order.email' defaultMessage={defineMessages.order.email} /> 
                </a></p>
                  
                  <p className='center-text mt-4'><h5 className='blue-text'><i>
                   <FormattedMessage id='order.pay' defaultMessage={defineMessages.order.pay} />
                  </i></h5></p>
                  <hr className='hr-fine-black width-120'></hr>
                  <span className='blue-text'>
                   <FormattedMessage id='order.payMet' defaultMessage={defineMessages.order.payMet} />
                  </span>                  
                   
                  <p className='center-text mt-4'><h5 className='blue-text'><i>
                   <FormattedMessage id='order.delivery' defaultMessage={defineMessages.order.delivery} />
                  </i></h5></p> 
                  <hr className='hr-fine-black width-120'></hr>
                  <div className=' center-text blue-text'>
                   <FormattedMessage id='order.delivery1' defaultMessage={defineMessages.order.delivery1} />                  
                   <FormattedMessage id='order.delivery2' defaultMessage={defineMessages.order.delivery2} /> 
                   <br /><FormattedMessage id='order.deliveryVar' defaultMessage={defineMessages.order.deliveryVar} />
                  </div>
                  
                  <ul className='blue-text'>
                    <li><FormattedMessage id='order.deliveryPost' defaultMessage={defineMessages.order.deliveryPost} /></li>
                    <li><FormattedMessage id='order.deliveryComp' defaultMessage={defineMessages.order.deliveryComp} /></li>
                    <li><FormattedMessage id='order.deliverySelf' defaultMessage={defineMessages.order.deliverySelf} /></li>
                    <li><FormattedMessage id='order.deliveryCur' defaultMessage={defineMessages.order.deliveryCur} /></li>
                  </ul>                  
                  <div className=' center-text blue-text'>
                   <FormattedMessage id='order.deliveryCond' defaultMessage={defineMessages.order.deliveryCond} />
                   <br /><b><FormattedMessage id='order.deliveryFree' defaultMessage={defineMessages.order.deliveryFree} /></b>                
                  </div>
                 
              <p className='center-text'></p>
              <p><i className='invisible'>_</i></p> 
              
              
                            
              
            </Col>
            <Col  md='2'></Col>
          </Row>
         </Container>
      
    </div>
  )
}
