import React, {useEffect, useState} from 'react'
import PageTitle from './PageTitle';
import ZaykaPage from "../Screens/Zayka";


const Zayka = () => {

    const lang = localStorage.getItem('lang');  
  const [message, setMessage] = useState('Авторская кукла | Зайка');
 useEffect(() => {
   if (lang === 'en') {
    setMessage(`Author's Doll | Little hare`);
   }
   if (lang === 'ua') {
    setMessage(`Авторська лялька | Зайка`);
   }
   if (lang === 'ru') {
    setMessage(`Авторская кукла | Зайка`);
   }
}, [lang]);

    return (
        <>
         <PageTitle 
            title={message}
         /> 
          <ZaykaPage />  
        </>
    )
}

export default Zayka
